<template>

  <div  class="auth-wrapper auth-v2">

    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <app-logo/>

        <h2 class="brand-text text-primary ml-1">
          SGA
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              :src="imgUrl"
              fluid
              alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
          >
            Restablecer contraseña
          </b-card-title>
          <b-card-text class="mb-2">
            Tu nueva contraseña debe ser diferente de las contraseñas usadas anteriormente
          </b-card-text>

          <!-- form -->

          <b-form
              class="auth-reset-password-form mt-2"
          >

            <!-- password -->
            <b-form-group
                label="Nueva contraseña"
                label-for="reset-password-new"
            >
              <b-input-group
                    class="input-group-merge"
                >
                  <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      class="form-control-merge"
                      name="reset-password-new"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
                label-for="reset-password-confirm"
                label="Confirmar contraseña"
            >
              <b-input-group
                  class="input-group-merge"
              >
                <b-form-input
                    id="reset-password-confirm"
                    v-model="confirm_password"
                    :type="password2FieldType"
                    class="form-control-merge"
                    name="reset-password-confirm"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- submit button -->
            <b-button
                block
                variant="primary"
                @click="handleSubmit"
            >
              Establecer nueva contraseña
            </b-button>
          </b-form>

          <validation-errors v-if="errors" :errors="errors" class="mt-2"/>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> Regresar
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->


    </b-row>

  </div>


</template>

<script>

import AppLogo from '@/modules/components/AppLogo.vue'

import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import { resetPassword } from '@/modules/auth/api'

export default {
  name: 'ResetPassword',
  components: {
    AppLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationErrors
  },
  data() {
    return {
      errors: null,
      isBusy: false,

      confirm_password: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {

    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },

    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    async handleSubmit(){

      try {

        this.isBusy = true

        const params = {
          'password': this.password,
          'confirm_password': this.confirm_password
        }

        await resetPassword( params )

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tu contraseña ha sido cambiada exitosamente',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        //eliminar token
        localStorage.removeItem('reset_token');

        //redirigir hacia login
        this.$router.push({ name: 'auth-signin' });


      } catch ( error ) {

        if ( error.response  ){
          // se ha hecho la peticion al servidor y se produjo un error


          // errores de validacion
          if ( error.response.status == 422 ) {

            console.log("error 422", error.response.data )
            // errores de validacion
            this.errors = error.response.data
            console.log(this.errors)

          }

          // errores de autenticacion
          if ( error.response.status == 401 ) {

            const  data  = error.response.data

            // errores de validacion
            this.errors = data

          }


        } else {
          // peticion no llegó al servidor

        }
      } finally {
        this.isBusy = false
      }

    }

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
