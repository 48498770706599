<template>

  <feather-icon
      icon="LogInIcon"
  />


</template>

<script>
export default {
  name: 'AppLogo'
}
</script>

<style scoped>

</style>
